import { Asset, PlaybackId, Upload } from "@mux/mux-node/dist/video/domain";
import {
  DeleteUsersResult,
  ListUsersResult,
  UserRecord,
} from "firebase-admin/auth";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  User,
} from "firebase/auth";
import React, { createContext, useContext, useState } from "react";
import { firebaseAuth } from "../../FirebaseConfig";
import { CustomTypes, StripeTypes } from "../../shared-types/v1";
// import { useLogin } from "../hooks/useLogin";

export type Customer = {
  id: string;
  object: string;
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  };
  balance: number;
  created: number;
  currency: string;
  default_source: null | string;
  delinquent: boolean;
  description: null | string;
  discount: null | string;
  email: string;
  invoice_prefix: string;
  invoice_settings: {
    custom_fields: null | string;
    default_payment_method: null | string;
    footer: null | string;
    rendering_options: null | string;
  };
  livemode: boolean;
  metadata: Record<string, unknown>;
  name: string;
  phone: null | string;
  preferred_locales: string[];
  shipping: null | string;
  tax_exempt: string;
  test_clock: null | string;
};

interface AuthContextInterface {
  // hasRole: (roles?: string[]) => {};
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  user?: User;
  isLoggedIn: () => boolean;
  dataLoading: boolean;
  // api stuff
  createVideoUpload: (playbackPolicy?: string | undefined) => Promise<Upload>;
  checkVideoUpload: (id: string) => Promise<Upload>;
  getAssets: (
    opts?:
      | {
          nextPageToken?: string | undefined;
          limit?: number | undefined;
        }
      | undefined
  ) => Promise<CustomTypes.PagedResponse<CustomTypes.Asset>>;
  editAsset: (
    id: string,
    assetInfo: CustomTypes.AssetInfo
  ) => Promise<CustomTypes.AssetInfo>;
  removeAsset: (id: string) => Promise<{
    success: boolean;
  }>;
  updatePlaybackPolicy: (id: string, policy: string) => Promise<PlaybackId>;
  getPrograms: () => Promise<CustomTypes.ProgramList>;
  removeProgram: (id: string) => Promise<{
    success: boolean;
  }>;
  editProgram: (
    program: CustomTypes.Program,
    id?: string | undefined
  ) => Promise<CustomTypes.Program>;
  getCollections: () => Promise<CustomTypes.Collection[]>;
  removeCollection: (id: string) => Promise<{
    success: boolean;
  }>;
  editCollection: (
    collection: CustomTypes.Collection,
    id?: string | undefined
  ) => Promise<CustomTypes.Collection>;
  getCategories: () => Promise<CustomTypes.AssetCategory[]>;
  removeCategory: (id: string) => Promise<{
    success: boolean;
  }>;
  editCategory: (
    category: CustomTypes.AssetCategory,
    id?: string | undefined
  ) => Promise<CustomTypes.AssetCategory>;
  getUsers: (
    maxResults?: number | undefined,
    pageToken?: string | undefined
  ) => Promise<ListUsersResult>;
  editUser: (
    userRecord: CustomTypes.PartialUserRecord,
    id?: string | undefined
  ) => Promise<UserRecord>;
  removeUsers: (userIds: string[]) => Promise<DeleteUsersResult>;
  getEventLogs: () => Promise<CustomTypes.EventLog[]>;
  getDailyTips: () => Promise<CustomTypes.DailyTip[]>;
  removeDailyTip: (id: string) => Promise<{
    success: boolean;
  }>;
  editDailyTip: (
    dailyTip: CustomTypes.DailyTip,
    id?: string | undefined
  ) => Promise<CustomTypes.DailyTip>;
  getAssetsMetadataOnly: () => Promise<CustomTypes.AssetInfo[]>;
  editAppSettings: (
    settings: CustomTypes.DynamicAppSettings
  ) => Promise<CustomTypes.DynamicAppSettings>;
  getAppSettings: () => Promise<CustomTypes.DynamicAppSettings>;
  editAvailableProducts: (
    products: StripeTypes.ProductWithPrice[]
  ) => Promise<StripeTypes.ProductWithPrice[]>;
  getAvailableProducts: () => Promise<StripeTypes.ProductWithPrice[]>;
  getStripeProducts: () => Promise<StripeTypes.ProductWithPrice[]>;
  getStripeCustomer: (email: string) => Promise<Customer>;
  mapUserAndStripeCustomer: (
    userId: string,
    customerId: string
  ) => Promise<boolean>;
  uploadVideoFromUrl: (
    url: string,
    playbackPolicy?: string | undefined
  ) => Promise<Asset>;
  getAsset: (id: string) => Promise<CustomTypes.Asset>;
  getOrCreateSubscriber: (userId: string) => Promise<Subscriber>;
  searchUsers: (search: string) => Promise<UserRecord[]>;
  searchAssets: (search: string) => Promise<CustomTypes.Asset[]>;
  postReceiptToRevenueCat: (
    userId: string,
    receipt: {
      checkoutSessionId?: string | undefined;
      subscriptionId?: string | undefined;
    }
  ) => Promise<any>;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  // const [authKey, setAuthKey] = useLocalStorage<string>("authkey", "");
  const [user, setUser] = useState<User>();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const [dataLoading, setDataLoading] = useState(false);

  const ensureToken = async () => {
    const token = await user?.getIdToken();
    if (token === undefined) {
      const curUser = getAuth().currentUser;
      if (curUser !== null) {
        setUser(curUser);
        return await curUser.getIdToken();
      } else {
        throw "No id token found";
      }
    }
    return token;
  };

  const isLoggedIn = () => {
    if (getAuth().currentUser !== null) {
      return true;
    }
    return false;
  };

  const handleLogin = async (email: string, password: string) => {
    setIsLoggingIn(true);
    try {
      const { user } = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      );
      const t = await user.getIdTokenResult();
      setIsLoggingIn(false);
      if (!t.claims.admin) {
        getAuth().signOut();
        throw "User is not admin";
      }
      return user;
    } catch (e: any) {
      setIsLoggingIn(false);
      throw e;
    }
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
    await getAuth().signOut();
    setIsLoggingOut(false);
    return;
  };

  // DATA OPERATIONS

  const getPrograms = async () => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/programs`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.ProgramList = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const removeProgram = async (id: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr =
      `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/programs?id=` + id;
    const res = await fetch(addr, {
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: { success: boolean } = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const editProgram = async (program: CustomTypes.Program, id?: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/programs`;
    if (id) {
      addr = addr + `?id=` + id;
    }
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(program),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.Program = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  //daily tips
  const getDailyTips = async () => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/dailytips`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.DailyTip[] = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const removeDailyTip = async (id: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr =
      `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/dailytips?id=` + id;
    const res = await fetch(addr, {
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: { success: boolean } = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const editDailyTip = async (dailyTip: CustomTypes.DailyTip, id?: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/dailytips`;
    if (id) {
      addr = addr + `?id=` + id;
    }
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dailyTip),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.DailyTip = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getCollections = async () => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/collections`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.Collection[] = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const removeCollection = async (id: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr =
      `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/collections?id=` +
      id;
    const res = await fetch(addr, {
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: { success: boolean } = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const editCollection = async (
    collection: CustomTypes.Collection,
    id?: string
  ) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/collections`;
    if (id) {
      addr = addr + `?id=` + id;
    }
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(collection),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.Collection = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getCategories = async () => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/categories`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.AssetCategory[] = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const removeCategory = async (id: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr =
      `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/categories?id=` +
      id;
    const res = await fetch(addr, {
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: { success: boolean } = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const editCategory = async (
    collection: CustomTypes.AssetCategory,
    id?: string
  ) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/categories`;
    if (id) {
      addr = addr + `?id=` + id;
    }
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(collection),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.AssetCategory = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  /** creates an upload object with url to upload a video to mux */
  const createVideoUpload = async (playbackPolicy?: string) => {
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/uploads`;
    if (playbackPolicy) {
      addr = addr + `?playback_policy=${playbackPolicy}`;
    }
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    if (res.ok) {
      const jsonRes: Upload = await res.json();
      return jsonRes;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  const uploadVideoFromUrl = async (url: string, playbackPolicy?: string) => {
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/uploads?url=${url}`;
    if (playbackPolicy) {
      addr = addr + `&playback_policy=${playbackPolicy}`;
    }
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    if (res.ok) {
      const jsonRes: Asset = await res.json();
      return jsonRes;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  /** checks existing video upload and returns it */
  const checkVideoUpload = async (id: string) => {
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/uploads?id=${id}`;
    const res = await fetch(addr, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
    if (res.ok) {
      const jsonRes: Upload = await res.json();
      return jsonRes;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getAsset = async (id: string) => {
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/assets?id=${id}`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    if (res.ok) {
      const jsonRes: CustomTypes.Asset = await res.json();
      return jsonRes;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getAssets = async (opts?: {
    nextPageToken?: string | undefined;
    limit?: number;
  }) => {
    const { nextPageToken, limit } = opts || {};
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/assets?filter=all`;
    if (limit) {
      addr = addr + `&limit=${limit}`;
    }
    if (nextPageToken) {
      addr = addr + `&startAfter=${nextPageToken}`;
    }
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    if (res.ok) {
      const jsonRes: CustomTypes.PagedResponse<CustomTypes.Asset> =
        await res.json();
      return jsonRes;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  const searchAssets = async (search: string) => {
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/assets?search=${search}`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    if (res.ok) {
      const jsonRes: CustomTypes.Asset[] = await res.json();
      return jsonRes;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getAssetsMetadataOnly = async () => {
    const extractMetadata = (asset: CustomTypes.Asset) => {
      return {
        ...asset.assetInfo,
        id: asset.id,
      } as CustomTypes.AssetInfo;
    };
    // fetch all assets and return only metadata
    const limit = 100;
    let nextPageToken: string | undefined = undefined;
    const initialResponse = await getAssets({ limit });
    let responses = initialResponse.data.map(extractMetadata);
    nextPageToken = initialResponse.nextPageToken;
    try {
      while (nextPageToken !== undefined) {
        const pagedResponse = await getAssets({ limit, nextPageToken });
        const rows = pagedResponse.data.map(extractMetadata);
        responses.concat(rows);
        nextPageToken = pagedResponse.nextPageToken;
      }
      return responses as CustomTypes.AssetInfo[];
    } catch (error: any) {
      throw new Error(error);
    }
  };

  const editAsset = async (id: string, assetInfo: CustomTypes.AssetInfo) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr =
      `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/assets?id=` + id;
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(assetInfo),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.AssetInfo = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const removeAsset = async (id: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr =
      `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/assets?id=` + id;
    const res = await fetch(addr, {
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: { success: boolean } = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const updatePlaybackPolicy = async (id: string, policy: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr =
      `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/playback-policy?id=` +
      id +
      `&policy=` +
      policy;
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: PlaybackId = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const searchUsers = async (search: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/users`;
    if (search) {
      addr = addr + `?&search=${search}`;
    }
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: UserRecord[] = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getUsers = async (maxResults?: number, pageToken?: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/users`;
    if (maxResults || pageToken) {
      addr = addr + `?`;
      if (maxResults) {
        addr = addr + `&maxResults=${maxResults}`;
      }
      if (pageToken) {
        addr = addr + `&pageToken=${pageToken}`;
      }
    }
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: ListUsersResult = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const editUser = async (
    userRecord: CustomTypes.PartialUserRecord,
    id?: string
  ) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/users`;
    if (id) {
      addr = addr + `?id=${id}`;
    }
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userRecord),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: UserRecord = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const removeUsers = async (userIds: string[]) => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/users`;
    const res = await fetch(addr, {
      method: "DELETE",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userIds: userIds }),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: DeleteUsersResult = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const editAppSettings = async (settings: CustomTypes.DynamicAppSettings) => {
    setDataLoading(true);
    const token = await ensureToken();
    const addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/dynamicsettings`;
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(settings),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.DynamicAppSettings = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getAppSettings = async () => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/dynamicsettings`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.DynamicAppSettings = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const editAvailableProducts = async (
    products: StripeTypes.ProductWithPrice[]
  ) => {
    setDataLoading(true);
    const token = await ensureToken();
    const addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/products`;
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(products),
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: StripeTypes.ProductWithPrice[] = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getAvailableProducts = async () => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/products`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: StripeTypes.ProductWithPrice[] = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const postReceiptToRevenueCat = async (
    userId: string,
    receipt: { checkoutSessionId?: string; subscriptionId?: string }
  ) => {
    const { subscriptionId } = receipt;
    const res = await fetch("https://api.revenuecat.com/v1/receipts", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + "strp_bKJGooJDHqEYYhWqQWccOQdFsAJ",
        "Content-Type": "application/json",
        "X-Platform": "stripe",
      },
      body: JSON.stringify({
        app_user_id: userId,
        fetch_token: subscriptionId,
      }),
    });

    if (res.status === 200) {
      const data = await res.json();
      return data;
    }
    return null;
  };

  const getStripeCustomer = async (email: string) => {
    // setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/stripe/customer?email=${email}`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    // setDataLoading(false);
    if (res.ok) {
      const jsonRes: Customer = await res.json();
      return jsonRes;
    } else {
      // setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getStripeProducts = async () => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/stripe/products`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: StripeTypes.ProductWithPrice[] = await res.json();
      return jsonRes;
    } else {
      setDataLoading(false);
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getEventLogs = async () => {
    setDataLoading(true);
    const token = await ensureToken();
    let addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/eventlog`;
    const res = await fetch(addr, {
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const jsonRes: CustomTypes.EventLog[] = await res.json();
      return jsonRes;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  const mapUserAndStripeCustomer = async (
    userId: string,
    customerId: string
  ) => {
    setDataLoading(true);
    const token = await ensureToken();
    const addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/mappings/user?&userId=${userId}&customerId=${customerId}`;
    const res = await fetch(addr, {
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    setDataLoading(false);
    if (res.ok) {
      return true;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  const getOrCreateSubscriber = async (userId: string) => {
    setDataLoading(true);
    const token = await ensureToken();
    const addr = `${process.env.REACT_APP_FUNCTIONS_ADDR}/api/v1/admin/subscriber?&id=${userId}`;
    const res = await fetch(addr, {
      method: "GET",
      headers: {
        Authorization: token,
        Accept: "application/json",
      },
    });
    setDataLoading(false);
    if (res.ok) {
      const resJson = await res.json();
      return resJson.subscriber as Subscriber;
    } else {
      throw `${res.status} ${await res.text()}`;
    }
  };

  // USE EFFECTS

  React.useEffect(() => {
    const auth = getAuth();
    return onAuthStateChanged(auth, (usr) => {
      if (usr) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        // setUser(user);
        setUser(usr);
        // ...
      } else {
        // User is signed out
        // ...
        setUser(undefined);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        // hasRole,
        isLoggedIn,
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout: handleLogout,
        dataLoading,
        user,
        createVideoUpload,
        checkVideoUpload,
        getAssets,
        editAsset,
        removeAsset,
        updatePlaybackPolicy,
        getPrograms,
        removeProgram,
        editProgram,
        getCollections,
        editCollection,
        removeCollection,
        getCategories,
        removeCategory,
        editCategory,
        getUsers,
        editUser,
        removeUsers,
        getEventLogs,
        getDailyTips,
        removeDailyTip,
        editDailyTip,
        getAssetsMetadataOnly,
        editAppSettings,
        getAppSettings,
        editAvailableProducts,
        getAvailableProducts,
        getStripeProducts,
        getStripeCustomer,
        mapUserAndStripeCustomer,
        uploadVideoFromUrl,
        getAsset,
        getOrCreateSubscriber,
        searchUsers,
        searchAssets,
        postReceiptToRevenueCat,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;

export interface Subscriber {
  entitlements: Entitlements;
  first_seen: string;
  last_seen: string;
  management_url: string;
  // non_subscriptions: NonSubscriptions
  original_app_user_id: string;
  original_application_version: any;
  original_purchase_date: string;
  // other_purchases: OtherPurchases
  // subscriber_attributes: SubscriberAttributes
  subscriptions: {
    [key: string]: {
      store: string;
      period_type: "normal" | "trial" | "intro";
      expires_date: string;
      is_sandbox: boolean;
      purchase_date: string;
    };
  };
}

export interface Entitlements {
  Membership: Membership;
}

export interface Membership {
  expires_date: string;
  grace_period_expires_date: any;
  product_identifier: string;
  purchase_date: string;
}
